export default [
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/wecamgirls.png',
        title: 'WeCamGirls',
        text: 'WeCamgirls is a community where cammodels can talk to their adult business colleagues. A safe place to interact, discuss and connect, without customers peeking in. You can also exchange tips and tricks or review all kinds of different cam related businesses.',
        buttonText: 'Read more',
        link: 'https://www.wecamgirls.com/register/?refer=10727',
        orderBy: 1,
        category: ['community', 'featured'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/manyvids.png',
        title: 'ManyVids',
        text: 'ManyVids.com is a platform designed specifically to help MV Stars generate a healthy passive income. MV is a “one-stop shop”, where your fans can find everything you have to offer.',
        buttonText: 'Read more',
        link: 'https://www.manyvids.com/Join-MV/578092',
        orderBy: 1,
        category: ['content', 'featured'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/rulta.png',
        title: 'Rulta',
        text: 'Protect your content with Rulta. If you are concerned about your live stream being recorded and shared through pirate and torrent sites, Rulta is a great tool to help you prevent that. It will scan the internet and detect your pirated content and get it removed for you.',
        buttonText: 'Read more',
        link: 'https://rulta.com/?ref=divatraffic',
        orderBy: 1,
        category: ['services', 'featured'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/stripperweb.png',
        title: 'Stripperweb',
        text: 'Forum for strippers and other members of the adult industry. Has a section for camming related discussion.',
        buttonText: 'Read more',
        link: 'https://www.stripperweb.com/',
        orderBy: 1,
        category: ['community'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/ynotnetwork.png',
        title: 'Ynot network',
        text: 'Complete social network with a news feed, forum, messaging, photos, videos, classifieds and more.',
        buttonText: 'Read more',
        link: 'https://www.ynotnetwork.com/',
        orderBy: 1,
        category: ['community'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/xbiz.png',
        title: 'Xbiz',
        text: 'B2B forum for the adult industry. You’ll find site owners, content producers, technology solution providers, affiliates, models and more on XBIZ.',
        buttonText: 'Read more',
        link: 'https://www.xbiz.net/',
        orderBy: 1,
        category: ['community'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/ambercutie.png',
        title: 'AmberCutie',
        text: 'AmberCutie is a professional camgirl that runs a forum on her site. It’s a great place to connect with other camgirls, ask for advice and share reviews, thoughts and strategies.',
        buttonText: 'Read more',
        link: 'https://www.ambercutie.com/forums/',
        orderBy: 1,
        category: ['community'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/lovense.png',
        title: 'Lovense',
        text: 'Lovense is a sex toy manufacturer known for its smart sex toys, which can be controlled via Bluetooth using a mobile app.',
        buttonText: 'Read more',
        link: 'https://www.lovense.com/',
        orderBy: 1,
        category: ['toys&equipment'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/ohmibod.png',
        title: 'OhMiBod',
        text: 'OhMiBod is the creator of the original iPod® vibrator. Headquartered in New Hampshire, USA, the female-owned and operated company is driving the evolution of the pleasure industry with an expansive range of high quality, body-safe products that combine technology with sensuality.',
        buttonText: 'Read more',
        link: 'http://www.ohmibod.com/',
        orderBy: 1,
        category: ['toys&equipment'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/kiiroo.png',
        title: 'Kiiroo',
        text: 'Kiiroo is a high-tech designer sex toy company that gives you the ability to feel someone else in a whole new way.',
        buttonText: 'Read more',
        link: 'https://www.kiiroo.com/',
        orderBy: 1,
        category: ['toys&equipment'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/terpon.png',
        title: 'Terpon',
        text: 'Terpon is a camera and digital interface manufacturing company that specializes Virtual Reality and fully immersive technology for the adult entertainment industry.',
        buttonText: 'Read more',
        link: 'https://terpon.com/',
        orderBy: 1,
        category: ['toys&equipment'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/infinitytelecom.png',
        title: 'Infinity Telecom',
        text: 'Infinity Telecom provides internet and webcam equipment for studios in Romania.',
        buttonText: 'Read more',
        link: 'https://infinitytelecom.ro/',
        orderBy: 1,
        category: ['toys&equipment'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/awsummit.png',
        title: 'AW Summit',
        text: 'AWSummit is the event where you can learn and network with the best and the brightest in our industry. AWSummit is no ordinary show - it is the ultimate business & conference experience!',
        buttonText: 'Read more',
        link: 'https://www.awsummit.com/',
        orderBy: 1,
        category: ['events'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/bucharestsummit.png',
        title: 'Bucharest Summit',
        text: 'Bucharest Summit is naturally taking place in Bucharest because the Romanian capital is an industry hotspot, priding itself with top business, talented models and innovative know-how. Experienced speakers will take you on a journey, unveiling all the secrets and sharing their expertise in a diverse range of topics.',
        buttonText: 'Read more',
        link: 'https://bucharestsummit.com/',
        orderBy: 1,
        category: ['events'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/bcams.png',
        title: 'BCams',
        text: 'BCams bring together websites, cam studios, independent models and a whole range of connected businesses from IT and financial companies to photographs, make-up artists, interior designers and marketing professionals. BCAMS It’s a great opportunity for networking in a friendly and neutral environment.',
        buttonText: 'Read more',
        link: 'https://bcams.ro/',
        orderBy: 1,
        category: ['events'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/theeuropeansummit.png',
        title: 'The European Summit',
        text: 'Founded in 2009, The European Summit brings together professionals from the online entertainment industry, e-commerce, financial, gaming, gambling, dating, forex, binary options, health, nutraceuticals and many others for an intensive 3-day networking event.',
        buttonText: 'Read more',
        link: 'https://www.theeuropeansummit.com/',
        orderBy: 1,
        category: ['events'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/bucharestawards.png',
        title: 'Bucharest Awards',
        text: 'The Award Galla takes place at the Bucharest Summit each year and recognizes the top studios, models, camming sites and professionals.',
        buttonText: 'Read more',
        link: 'https://bucharestsummit-awards.com/',
        orderBy: 1,
        category: ['awards'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/ynotcamawards.png',
        title: 'Ynot Cam Awards',
        text: 'The YNOT Cam Awards highlights the women and men who perform in the adult interactive community.',
        buttonText: 'Read more',
        link: 'https://awards.ynotcam.com/',
        orderBy: 1,
        category: ['awards'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/lalexpo.png',
        title: 'LaLexpo',
        text: 'The Definitive B2B Adult show of Central and South America. The only live cam award show in South America. Meet a totally new market and be part of the only business show in the adult industry for professionals that also have more than 600 models and webcams studios around the world.',
        buttonText: 'Read more',
        link: 'https://www.lalexpo.com/en/index.php',
        orderBy: 1,
        category: ['awards'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/livecamawards.png',
        title: 'Live Cam Awards',
        text: 'Live Cam Awards is an annual award show that was launched for the first time in 2015, being at that time the first award show to exclusively honor the performers and companies who serve the now highly-competitive webcam industry.',
        buttonText: 'Read more',
        link: 'http://livecamawards.com/',
        orderBy: 1,
        category: ['awards'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/awawards.png',
        title: 'AW Awards',
        text: 'The AW Awards and Conference is one of the world’s largest camming award show and event. The AW Awards recognizes the best in the camming industry, including models, studios and businesses.',
        buttonText: 'Read more',
        link: 'http://www.aw-awards.com/',
        orderBy: 1,
        category: ['awards'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/webcamstartup.png',
        title: 'Webcam Startup',
        text: 'Webcam Startup is the largest model resource site on the internet. Webcam Startup brings in a wealth of knowledge from a wide range of contributors and staff, each with their own experience and respective knowledge. Plus, all the industry news and announcements.',
        buttonText: 'Read more',
        link: 'http://webcamstartup.com/',
        orderBy: 1,
        category: ['news'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/awnews.png',
        title: 'AW News',
        text: 'AW News is the largest live cam industry news source. It’s your provider of the freshest news in the live cam biz.',
        buttonText: 'Read more',
        link: 'http://awnews.com/',
        orderBy: 1,
        category: ['news'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/ynotcam.png',
        title: 'Ynot Cam',
        text: 'YNOT Cam is an online magazine that offers tips and advice to camgirls.',
        buttonText: 'Read more',
        link: 'https://www.ynotcam.com/',
        orderBy: 1,
        category: ['news'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/manycam.png',
        title: 'ManyCam',
        text: 'ManyCam is a free webcam software and video switcher that allows you to enhance your video chats and create amazing live streams on multiple platforms at the same time.',
        buttonText: 'Read more',
        link: 'https://manycam.com/',
        orderBy: 1,
        category: ['software'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/loomly.png',
        title: 'Loomly',
        text: 'Loomly is a simple social media calendar tool.',
        buttonText: 'Read more',
        link: 'https://www.loomly.com/',
        orderBy: 1,
        category: ['social'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/hootsuite.png',
        title: 'Hootsuite',
        text: 'Hootsuite is a social media management platform. The system’s user interface takes the form of a dashboard and supports social network integrations for Twitter, Facebook, Instagram, LinkedIn, Google+ and YouTube.',
        buttonText: 'Read more',
        link: 'https://hootsuite.com/',
        orderBy: 1,
        category: ['social'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/onelink.png',
        title: 'OneLink',
        text: 'If you had one shot, one opportunity, OneLink, Would you use it or just let it slip? Never change your bio link again.OneLink makes you link to all your online stuff on one simple page.',
        buttonText: 'Read more',
        link: 'https://onelink.cam/',
        orderBy: 1,
        category: ['social'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/modelcentro.png',
        title: 'ModelCentro',
        text: 'ModelCentro is a platform that helps you monetize and grow your existing fan base into monthly recurring revenues. It’s 100% FREE to set up a Personal Paid Fan Site.',
        buttonText: 'Read more',
        link: 'https://modelcentro.com/',
        orderBy: 1,
        category: ['content'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/clips4sale.png',
        title: 'Clips4sale',
        text: 'Clips4Sale (C4S) is an American adult video content selling website. It is known for fetish content and was the first downloadable clip site of its kind',
        buttonText: 'Read more',
        link: 'https://www.clips4sale.com/',
        orderBy: 1,
        category: ['content'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/extralunchmoney.png',
        title: 'ExtraLunchMoney',
        text: 'ExtraLunchMoney.com (ELM) is a digital marketplace for buyers to directly interact with adult content creators. Although the focus of the site is amateur porn, custom fetish clips, and amateur adult content, we welcome a wide range of adult content including audio and written work from amateurs and professionals alike. Real amateur porn and custom adult content direct from the performers themselves.',
        buttonText: 'Read more',
        link: 'https://extralunchmoney.com/?ref_user=DivaTraffic',
        orderBy: 1,
        category: ['content'],
    },
    {
        img: 'https://storage.googleapis.com/files.divatraffic.com/img/resources/pineapplesupport.png',
        title: 'Pineapple Support',
        text: 'Pineapple Support provides a 24/7 emotional support system for all adult industry performers & producers.',
        buttonText: 'Read more',
        link: 'https://pineapplesupport.com/',
        orderBy: 1,
        category: ['services'],
    },
]