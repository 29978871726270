<template>
      <div class="row">
        <div class="col-12">
          <card>
            <div class="row">
              <div class="col-12" ref="resourceTabs">
                <card class="mb-0">
                  <el-tabs @tab-click="tabhandler" v-model="activeTab">
                    <el-tab-pane label="Featured" name="featured"></el-tab-pane>
                    <el-tab-pane
                      label="Community"
                      name="community"
                    ></el-tab-pane>
                    <el-tab-pane
                      label="Toys&Equipment"
                      name="toys&equipment"
                    ></el-tab-pane>
                    <el-tab-pane label="Events" name="events"></el-tab-pane>
                    <el-tab-pane label="Awards" name="awards"></el-tab-pane>
                    <el-tab-pane label="News" name="news"></el-tab-pane>
                    <el-tab-pane label="Software" name="software"></el-tab-pane>
                    <el-tab-pane label="Social" name="social"></el-tab-pane>
                    <el-tab-pane label="Content" name="content"></el-tab-pane>
                    <el-tab-pane label="Services" name="services"></el-tab-pane>
                  </el-tabs>
                </card>
              </div>
            </div>
            <div class="row pt-4 mb-0">
              <el-carousel
                ref="resourceCarousel"
                class="col-12 text-center"
                height="500px"
                indicator-position="outside"
                :autoplay="false"
                arrow="always"
              >
                <el-carousel-item
                  class="col-12"
                  v-for="(carouselItem, i) in filteredForCarousel"
                  :key="i"
                >
                  <div class="row">
                    <div
                      class="col-12 col-md-6 col-lg-3"
                      v-for="(featuredItem, k) in carouselItem"
                      :key="k"
                    >
                      <card class="h-100">
                        <div
                          class="card-img-overlay"
                          v-if="featuredItem.category.includes('featured')"
                        >
                          <badge
                            rounded
                            type="success"
                            class="featured float-right"
                            size="lg"
                            >{{getTrans('messages.featured')}}</badge
                          >
                        </div>
                        <template v-slot:image>
                        <img
                          class="card-img-top"
                          :src="featuredItem.img"
                          :alt="featuredItem.title"
                        />
                        </template>
                        <h5 class="card-title">{{ featuredItem.title }}</h5>
                        <p class="card-text">{{ featuredItem.text }}</p>
                        <base-button
                          tag="a"
                          :href="featuredItem.link"
                          class="align-self-end"
                          size="sm"
                          type="danger"
                          >{{ featuredItem.buttonText }}</base-button
                        >
                      </card>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </card>
        </div>
      </div>
</template>
<script>
// Components
import Resources from "../assets/data/resources.js";
import { ElTabs, ElTabPane, ElCarousel, ElCarouselItem } from "element-plus";
import Card from "@/components/Cards/Card.vue";
export default {
  components: {
    Card,
    [ElTabs.name]: ElTabs,
    [ElTabPane.name]: ElTabPane,
    [ElCarousel.name]: ElCarousel,
    [ElCarouselItem.name]: ElCarouselItem
  },
  data() {
    return {
      rollingResources: "",
      activeTab: "featured",
      resources: Resources,
      computedRefresher: 0
    };
  },
  computed: {
    filteredResourses() {
      return this.resources
        .filter(resource => {
          return resource.category.indexOf(this.activeTab) >= 0;
        })
        .sort((a, b) => {
          if (a.orderBy < b.orderBy) {
            return -1;
          } else if (a.orderBy > b.orderBy) {
            return 1;
          }
          return 0;
        });
    },
    featuredList() {
      return this.resources
        .filter(resource => {
          return resource.category.indexOf("featured") >= 0;
        })
        .sort((a, b) => {
          if (a.orderBy < b.orderBy) {
            return -1;
          } else if (a.orderBy > b.orderBy) {
            return 1;
          }
          return 0;
        })
        .slice(0, 3);
    },
    filteredForCarousel() {
      var width = window.innerWidth;
      var chunk = 3;
      if (width >= 1200) {
        chunk = 4;
      } else if (width >= 992) {
        chunk = 2;
      } else {
        chunk = 1;
      }

      var result = [];
      var fullList = this.filteredResourses;
      for (var i = 0, j = fullList.length; i < j; i += chunk) {
        result.push(fullList.slice(i, i + chunk));
      }
      // eslint-disable-next-line
      this.computedRefresher = 1 + this.computedRefresher;
      return result;
    }
  },
  methods: {
    scrolltoElement(element) {
      var elementPos = this.$refs[element].offsetTop;
      window.scrollTo({
        top: elementPos,
        left: 0,
        behavior: "smooth"
      });
    },
    onResize() {
      this.computedRefresher = 1 + this.computedRefresher;
    },
    tabhandler() {
      this.scrolltoElement("resourceTabs");
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>
<style></style>
